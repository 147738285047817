import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/economics/*" />
    <RAC path="/zh-cn/economics/*" />
    <RAC path="/zh-tw/economics/*" />
    <RAC path="/pl/economics/*" />
    <RAC path="/ru/economics/*" />
    <RAC path="/it/economics/*" />
    <RAC path="/de/economics/*" />
    <RAC path="/es/economics/*" />
    <RAC path="/fr/economics/*" />
    <RAC path="/ar/economics/*" />
    <RAC path="/id/economics/*" />
    <RAC path="/pt/economics/*" />
    <RAC path="/ko/economics/*" />
    <DynamicNotFoundPage default />
  </Router>
)
